import { create } from "zustand";

interface OnboardingState {
  chatSeenAt: boolean,
  setChatSeenAt: (v:boolean)=>void,

  ideationSeenAt: boolean,
  setIdeationSeenAt: (v:boolean)=>void,

  desktopAppSeenAt: boolean,
  setDesktopAppSeenAt: (v:boolean)=>void,

  ideationTabButtonSeenAt: boolean,
  setIdeationTabButtonSeenAt: (v:boolean)=>void,

}

export const useOnboarding = create<OnboardingState>(( set ) => ({
  chatSeenAt: false,
  setChatSeenAt: (chatSeenAt:boolean)=>set({ chatSeenAt }),

  ideationSeenAt: false,
  setIdeationSeenAt: (ideationSeenAt:boolean)=>set({ ideationSeenAt }),

  desktopAppSeenAt: false,
  setDesktopAppSeenAt: (desktopAppSeenAt:boolean)=>set({ desktopAppSeenAt }),

  ideationTabButtonSeenAt: false,
  setIdeationTabButtonSeenAt: (ideationTabButtonSeenAt:boolean)=>set({ ideationTabButtonSeenAt }),
}))
